import Layout from 'views/layout/Layout';

export default {
  path: '/members',
  redirect: '/members/list',
  name: 'members',
  component: Layout,
  title: '会员管理',
  permission: ['member'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'list',
      component: () => import('views/member/List'),
      title: '会员列表',
      keepAlive: true,
      name: 'MemberList',
      permission: ['member', 'list'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z"
    },
    {
      path: 'operating',
      title: '会员运营',
      component: () => import('views/member_operating/Index'),
      name: 'MemberOperating',
      permission: ['member', 'member_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs"
    },
    {
      path: 'group_operating',
      title: '团课会员',
      // redirect: '/members/group_operating/new_members',
      component: () => import('views/group_training_member_operating/Index'),
      name: 'GroupTrainingMemberOperating',
      permission: ['member', 'group_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs",
    },
    {
      path: 'elite_member_operating',
      title: '私教会员',
      // redirect: '/members/elite_member_operating/active_members',
      component: () => import('views/elite_member_operating/Index'),
      name: 'EliteMembersOperating',
      permission: ['member', 'elite_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs",
    }
  ],
};
