<template>
  <el-select
    ref="select"
    :loading="loading"
    :value="value"
    @change="(v) => $emit('change', v)"
    class="item"
    remote
    filterable
    :placeholder="placeholder"
    size="small"
    clearable
    @hook:mounted="cancalReadOnly"
    @visible-change="cancalReadOnly"
    :remote-method="handleSearch"
    :disabled="disabled"
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="`${item.name}/${item.phone ? item.phone : '-'}`"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { isNumber } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    kidsOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择会员',
    },
    defaultIndex: Number,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh, handleSearch } = useSelect(context, {
      defaultIndex: props.defaultIndex,
      fetchApi: (query) =>
        props.kidsOnly
          ? API.memberMemberKids({ id: props.value, query })
          : API.memberMemberList({ id: props.value, query }),
    })
    return {
      ...toRefs(state),
      handleSearch,
    }
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs
          const input = select.$el.querySelector('.el-input__inner')
          input.removeAttribute('readonly')
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
