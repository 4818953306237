import Layout from 'views/layout/Layout';

export default {
  path: '/members_v2',
  redirect: '/members_v2/index',
  name: 'members_v2',
  component: Layout,
  title: '会员管理(新)',
  permission: ['member_v2'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'index',
      component: () => import('views/member/Index'),
      title: '会员列表',
      keepAlive: true,
      name: 'MemberListV2',
      permission: ['member_v2', 'list'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z"
    },
    {
      path: 'member_operating',
      title: '会员运营',
      component: () => import('views/member/Operating'),
      name: 'MemberOperatingV2',
      permission: ['member_v2', 'member_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs"
    },
  ],
};
