import Layout from 'views/layout/Layout';

export default {
  path: '/member',
  redirect: 'noredirect',
  component: Layout,
  name: 'memberDetailIndex',
  title: '会员详情',
  permission: [['member', 'view'], ['member_v2', 'view']],
  helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z",
  hide: true,
  children: [
    {
      path: ':memberId',
      redirect: '/member/:memberId/membership',
      component: () => import('views/member/Detail'),
      name: 'memberDetail',
      title: '会员详情',
      permission: [['member', 'view'], ['member_v2', 'view']],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z",
      children: [
        {
          path: 'membership',
          component: () => import('views/member/detail/Membership'),
          name: 'memberDetailMembership',
          title: '余额统计',
          children: [
            {
              path: 'records',
              component: () => import('views/member/record/Membership'),
              name: 'memberRecordMembership',
              title: '账户使用记录',
              root: true,
            },
          ],
        },
        {
          path: 'training',
          component: () => import('views/member/detail/Training'),
          name: 'memberDetailTraining',
          title: '训练统计'
        },
        {
          path: 'order',
          component: () => import('views/member/detail/Order'),
          name: 'memberDetailOrder',
          title: '消费统计'
        },
        {
          path: 'fitness_test',
          component: () => import('views/member/detail/FitnessTest'),
          name: 'memberDetailFitnessTest',
          title: '体测记录'
        },
        {
          path: 'account_member/list',
          component: () => import('views/member/components/AccountMembers'),
          name: 'membershipAccountMemberList',
          title: '共享卡管理',
          root: true,
        },
      ],
    }
  ],
}