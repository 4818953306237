import store from "../store/"
import { isString, throttle } from 'lodash'
import { isObject, uniqueId} from 'lodash'
import { getElementTop } from '@/utils/dom'
import {any, curry } from 'ramda'

const hasPermission = (grantedPermissions, requiredPermission) => {
  let key = requiredPermission[0]
  let subKey = requiredPermission[1]
  
  if (Array.isArray(key)) {
    return any(curry(hasPermission)(grantedPermissions))(requiredPermission)
  }

  let category = grantedPermissions[key]
  return category && (!subKey || category.includes(subKey))
}

export const permission = {
  inserted: (el, {value}) => {
    if (isString(value)) {
      value = [value]
    } else if (!Array.isArray(value)) {
      throw Error('permission指令参数类型必须为数组')
    }

    // TODO: support multiple permission

    let permissions = store.state.user.permissions
    if (!hasPermission(permissions, value)) {
      el.parentNode.removeChild(el)
    }
  },
}

let handleScroll = (el, style) => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  temp.forEach(item => {
    let { top, style, el } = item
    if (style.hasOwnProperty('top')) {
      top -= style.top
    }
    if (scrollTop > top) {
      el.classList.add('affix')
      setStyle(el, style)
    } else {
      el.classList.remove('affix')
    }
  })
}

function setStyle(el, style) {
  for (const key in style) {
    if (style.hasOwnProperty(key)) {
      const value = style[key];
      el.style[key] = `${value}px`
    }
  }
}

let scroll = throttle(function() {
  handleScroll()
}, 30)

let temp = []

export const affix = {
  inserted: (el, { value }) => {
    if (!isObject(value)) {
      return Error('affix指令参数类型出错，只能是对象')
    }
    let obj = {
      el,
      top: getElementTop(el),
      style: value,
    }
    temp.push(obj)
    window.addEventListener('scroll', scroll)
  },
  
  unbind: () => {
    window.removeEventListener('scroll', scroll)
    temp = []
  }
}